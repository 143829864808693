import * as React from 'react';
import ServiceItem from './ServiceItem';




const ServiceMenu = () => {

    const dropInVisit = {
        name: "Drop in Visit",
        // priceId: "price_1O2zahFdp7FLr2zYWKBOnAxf", //TEST
        priceId: "price_1O9WIKFdp7FLr2zY0GK5WEwI", // PROD
        price: 20
    }

    const boarding =  {
        name: "Boarding",
        // priceId: "price_1O2zaOFdp7FLr2zYXhg709Mi", //TEST
        priceId: "price_1O9WJPFdp7FLr2zY9eHIhmLp", // PROD
        price: 50
    }
    const daycare =  {
        name: "Daycare",
        // priceId: "price_1O2zZiFdp7FLr2zYjNTcOjw0", //TEST
        priceId: "price_1O9WKAFdp7FLr2zYMgMFyyEp", // PROD
        price: 40
    }
    const meetAndGreet =  {
        name: "Meet and Greet",
        priceId: "price_1O2zZ1Fdp7FLr2zYaNjDxB4Y", 
        price: 0
    }

    const dogWalk =  {
        name: "Dog Walk",
        // priceId: "price_1O2gkhFdp7FLr2zYpv8rUUi1", // TEST
        priceId: "price_1O9WLAFdp7FLr2zY2x9AHqB0", // PROD
        
        price: 25
    }
    
  

    return (

        
       <div className="flex justify-center">

      
            <section id="book" className="py-32">
                
         {/* CALENDAR*/}

            <div className ="flex justify-center gap-16 mt-5">

                            <iframe src="https://calendar.google.com/calendar/embed?height=450&wkst=1&bgcolor=%23ffb7b7&ctz=America%2FNew_York&showPrint=0&showNav=1&showTabs=0&showCalendars=1&showDate=0&showTz=0&src=OTdlZDM4YzMzYTcwOTkyMDM1MjY5ZGU5OWFiZjYzZmE2MmFkNzhmZTViMDRkNDhjNDI1NjM2ZWNkNjE5OWRjNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23cc8181"
                              
                            style={{float : 'left',borderWidth:"0", paddingRight : '1px', height: '350px', width: '350px', frameborder:"0", scrolling:"no"}}></iframe>

              
                </div>

            <p className="text-xl font-playfair z-10 text-center md:text-start text-black p-6 flex justify-center w-full">
                <span className="font-playfair font-bold ">Services </span>  </p>

            <div className="container rounded-md text-sm" >
                <ServiceItem
                            title={meetAndGreet.name}
                            priceInfo = "$0"
                            priceId={meetAndGreet.priceId}
                            priceVal={meetAndGreet.price}                       
                            text="Free meet-and-greets before all bookings to meet you and your pets"
                            id="meet-and-greet"
                    
                            
                        />
                            

                    <ServiceItem
                        title={dogWalk.name}
                        priceInfo = "$25 (30 mins), $35 (1 hour) ⁃ $5 per additional dog"
                        priceId={dogWalk.priceId}
                        priceVal={dogWalk.price}
                        text="Come to your home to take your dog out for a walk and a potty break"
                        id="walking"
                
                    />

        
                    <ServiceItem
                        title={dropInVisit.name}
                        priceInfo = "$20 (20 mins) ⁃ $5 per additional pet"
                        priceId={dropInVisit.priceId}
                        priceVal={dropInVisit.price}
                        text="Arrive at your home to care for your pets. This includes feeding, administering medications, potty breaks, and lots of cuddles and treats!"
                        id="drop-in-visits"
                    
                    />

                    <ServiceItem
                        title={daycare.name}
                        priceInfo = "$40 / day ⁃ $15 per additional pet."
                        priceId={daycare.priceId}
                        priceVal={daycare.price}
                        text="Up to an 8 hour day time stay in my home"
                        id="daycare"
                
                    />

                    <ServiceItem
                        title={boarding.name}
                        priceInfo = "$50 / night ⁃ $15 per additional pet. Nightly rates are based on a 24 hour time period from drop off to pick up. Extended rates (3-8 hours is 50% and 8+ hours is 100% of the nightly rate)"
                        priceId={boarding.priceId}
                        priceVal={boarding.price}
                        text=" Over night care in my home for your pets while you are away from home 
                        
                        " 
                        id="boarding"
                    
                        
                    />

            </div>
     
                   

            </section>



        </div>
            
    )
};



export default ServiceMenu;