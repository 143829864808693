
import * as React from 'react';
import { motion } from "framer-motion";

import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import {
    addToCart
  } from "../../state"

import PetsIcon from '@mui/icons-material/Pets';



const Service = ({ title, priceInfo, text, id, priceId, priceVal}) => {
  

    const services = {
        serviceId: priceId,
        name: title,
        price: priceVal
        

    }

    const dispatch = useDispatch();


    const handleClick = () => {
        dispatch(addToCart({services}))  
    
   
    }
 

    return (
        <div className="pb-1 w-11/12 mx-auto">

            <Link to="/appointments">
                 <input type="checkbox" id={id} value="" className="hidden peer" required=""/>
                <label for={id} onClick={()=>handleClick()} className="font-playfair font-semibold inline-flex items-center
                justify-between w-full p-2 text-gray-500 bg-white cursor-pointer hover:text-black hover:bg-mint rounded-lg shadow-md">  
  
                                 
            <div className="block">
               
                <span className="text-salmon">{<PetsIcon/>}</span><span className="text-md md:text-lg font-bold px-2">{title}</span>
                <div className="w-full text-md pt-1 mx-auto px-8">{priceInfo}</div>
                <div className="w-full text-md  pt-1 mx-auto px-8">{text}</div>
                <div>   
            </div>   
             

                </div>
            </label>    
                
                
         
                
        </Link>
        


    </div>
    );
  };

  export default Service;