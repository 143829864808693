import { motion } from "framer-motion";




const Testimonials = () => {

    const testimonialStyles = `mx-auto relative max-w-[400px] h-[350] flex flex-col
        justify-end p-16 mt-48 before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2`;

    return (

        <section id="testimonials" className="pt-32 md:pt-2 pb-8 w-5/6 mx-auto">

            {/* HEADING */}

            <motion.div 
                className="md:w-1/3 text-center md:text-left"
                initial="hidden"
                whileInView={"visible"}
                viewport={{ once: true, amount: 0.5 }}
                transition={{duration: 0.5}}
                variants={{
                    hidden: {opacity: 0, x:-50 },
                    visible : {opacity: 1, x: 0  }
                }}
               >

                <p className="font-euphoria text-6xl mb-5 text-salmon lg:mt-64">
                    Testimonials
                   
                
                </p>
 

               </motion.div>


               {/* TESTIMONIALS */}

               {/* ROW 1*/}

               <div className="md:flex md:justify-between gap-8 text-black font-semibold">
               
                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person1`}
                        
                   
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                    >
                        
                    <p className="font-playfair text-4xl">Kathy E.</p>
                   
                    <p className="text-center text-xl pt-10">
                        
               
                    Stephanie is so kind and patient with my often anxious Chi-mix.
                     He is calm and content when at her place, which puts my mind at ease, and her video and photos are reassuring too. Definitely recommend. 
                    </p>
                    </motion.div>


           
                    

                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person3`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                        >
                    <p className="font-playfair text-4xl">Kelsea E.</p>
                    <p className="text-center text-xl pt-10">
                    
                    Stephanie is absolutely amazing. Constantly updating me on how my dogs are doing, and sending such adorable pictures.
                    I feel 100000 % at ease when my dogs are with her. I will continue to use Stephanie with my pups for the foreseeable future. 
                    </p>
                    </motion.div>

                    <motion.div
                    className={`bg-mint ${testimonialStyles} before:content-person5`}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, scale: 0.8 },
                        visible: { opacity: 1, scale: 1 },
                    }}
                    >
                        
                    <p className="font-playfair text-4xl">Khushboo R.</p>
                   
                    <p className="text-center text-xl pt-10">
                        
               
                    The dogs love her and she is very responsive and provides feedback/pictures throughout the visits. The pups come back tired and happy and I am so happy we were able to find Stephanie when we travel.
                    </p>
                    </motion.div>



               </div>

                  {/* ROW 2*/}

               
               <div className="md:flex md:justify-between gap-8 text-black font-semibold">
          

                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person2`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                        >
                    <p className="font-playfair text-4xl">Annaliese V.</p>

                    <p className="text-center text-xl pt-10">
                 
                      
                    Stephanie went above and beyond for my pup. She was an awesome communicator and sent so many updates and pictures. I was nervous about boarding because my dog is 8 and has never been left with someone she didn't know, 
                    but stephanie did a meet and greet before and made sure everything went so smoothly. I would recommend her to anyone I know!! She's the best.
                    </p>
                    </motion.div>


  
                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person9`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                        >
                    <p className="font-playfair text-4xl">Tyler G.</p>

                    <p className="text-center text-xl pt-10">
                 
                      
                    Stephanie was great to work with! She took really great care of Abby, and made her feel right at home.
                     She was very communicative and flexible with drop off and pick up. Abby has a bad back that flares up from time to time so it's 
                     reassuring to have someone we can trust take care of her. Thank you, Stephanie! 
                    </p>
                    </motion.div>

                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person4`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                        >
                    <p className="font-playfair text-4xl">Brin R.</p>
                    <p className="text-center text-xl pt-10">
                    
                    Stephanie is amazing! We were out of town for 10 days and she provided top notch love and care to our 1.5 year old dog. 
                    Her communication was prompt, and she shared updates throughout our dogs stay. She clearly made our dog feel very comfortable 
                    (and our dog can get scared when apart from us), and was very happy and calm when we came to get her. Thanks Stephanie!  
                    </p>
                    </motion.div>

                    </div>

               <div className="md:flex md:justify-between gap-8 text-black font-semibold">
                    <motion.div
                    className={`bg-mint ${testimonialStyles} before:content-person8`}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2 }}
                    variants={{
                        hidden: { opacity: 0, scale: 0.8 },
                        visible: { opacity: 1, scale: 1 },
                    }}
                    >

                    {/* ROW 3*/}


                    <p className="font-playfair text-4xl">Brooks H.</p>
                   
                    <p className="text-center text-xl pt-10">
                        
               
                    Stephanie did a fabulous job watching my pups for just over a week! It was the longest I've been gone in a long time and while they've been boarded before,
                     they're still relatively new to having someone outside of my family watch them. I have 2 very large and ''quirky'' dogs :)
                     so I was thrilled to hear things went well and appreciated the steady communication and photos. Will 110 % be reaching out to Stephanie again the next time I need someone to watch them! 
                    </p>
                    </motion.div>

                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person4`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                        >
                    <p className="font-playfair text-4xl">Phi P.</p>

                    <p className="text-center text-xl pt-10">
                 
                    Stephanie is absolutely amazing. She made Creed so comfortable knowing he has anxiety and was super gentle with how she approached him. 
                    She asked so many questions prior to dog sitting Creed and even accommodated to Creed's needs! Creed wasn't 
                    eating much at first and had a tough time with his meds but Stephanie went out of her way and bought and boiled chicken to get Creed to eat again.
                     And for that, I'm super appreciative of. It's lovely to be able to leave your pets with someone you can trust and know they'll care for them. 
                    </p>
                    </motion.div>


                    
                    

                    <motion.div
                        className={`bg-mint ${testimonialStyles} before:content-person4`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, scale: 0.8 },
                            visible: { opacity: 1, scale: 1 },
                        }}
                        >
                    <p className="font-playfair text-4xl">Alfreda R.</p>
                    <p className="text-center text-xl pt-10">
                    
                    Stephanie did a wonderful job caring for my fur babies while I was away. She was especially patient and forgiving of my baby with severe anxiety issues.
                     She gave both love and attention and to my surprise even managed to get both of them to take their medications without issue. 
                     I enjoyed and appreciated receiving her updates, pictures and video. I will definitely be bringing them for future visits. 
                    </p>
                    </motion.div>





               </div>
         

        </section>
    )



} 

export default Testimonials;