
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";



const Landing = () => {
    
    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
    
    

    return(
        <section id="home" className="md:justify-between md:items-center md:h-full gap-16 py-16 w-5/6 mx-auto md:h-100">

            {/* IMAGE */}

            <motion.div 
                    initial="hidden"
                    // whileInView={{opacity: 1 }}
                    whileInView={"visible"}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{duration: 0.5}}
                    variants={{
                    hidden: {opacity: 1, x: -50 },
                    visible : {opacity: 1, x:0 }
                }}
            >



            <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16 md:mt-32 font-euphoria md:text-6xl text-5xl text-salmon">

                Steph's Pet Retreat
            {/* <img 
                className="z-10"
                src="../assets/logo_white_banner.png" 
                alt="logo_banner"
                />                */}

            </div>


            <div className="md:order-2 flex justify-center basis-3/5 z-10 md:mt-32">
                <p className="mt-20 mb-7 text-xl text-center md:text-start text-black font-semibold">

                    A professional pet care service in the metro Atlanta area serving all of your pet care needs!

                </p>
                
            </div>
            

                </motion.div>

                <div className="md:order-2 flex justify-center basis-3/5 z-10 md:mt-32">

                        <a 
                            className="p-5 bg-mint font-semibold text-black mt-5 text-xl hover:bg-salmon hover:text-black transition duration-500 rounded"
                            href="/book" >
                            
                            Book a Service!
                        </a>

                </div>

                <div className="md:order-2 flex justify-center basis-3/5 z-10 md:mt-32">

     

            </div>
          
        </section>
        
    )


}

export default Landing;