import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { useEffect } from "react";

import sendEmail from "../../api/sendEmail";


import { useDispatch } from "react-redux";
import { addToEmailSent } from "../../state";
import { motion } from "framer-motion";




const Confirmation = () => {
    let email = JSON.parse(localStorage.getItem('email'));
    
    // sendToCustomerEmail(email[email.length-1])
    const dispatch = useDispatch();
   

    


    let wasEmailSent = window.localStorage.getItem("emailSent");
    let dates = window.localStorage.getItem("dates");
    let times = window.localStorage.getItem("times");
    let cart = window.localStorage.getItem("cart");
    let formData = window.localStorage.getItem("formData");

    const emailSentValue = JSON.parse(wasEmailSent)

    
    const service = JSON.parse(cart);
    const serviceName = service[0].services.name;

    const start = JSON.parse(times);
    const startTime = start[start.length-1].startTime;
    const end = JSON.parse(times);
    // console.log(startTime)
    const endTime = end[end.length-1].endTime;

    const endD = JSON.parse(dates);
    const endDate = endD[endD.length-1].dates.endDate.substring(0,10);

    const startD = JSON.parse(dates);
    const startDate = startD[startD.length-1].dates.pickUpDate.substring(0,10);

    const confirmation = {
        formData: formData,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime

    }

    
    

    useEffect(() => {
        window.scrollTo(0,0)
        console.log(emailSentValue);

        if (emailSentValue[emailSentValue.length-1] !== 'true') {
            let e = email[email.length-1]
            sendEmail(e.replace(/['"]+/g, ''),'Booking confirmation', serviceName,startDate,endDate,startTime,endTime,{});
            sendEmail('stephspetretreat@gmail.com','New Booking confirmation', serviceName,startDate,endDate,startTime,endTime,formData);
            dispatch(addToEmailSent('true'))

        }
       
 
      }, []);


    return (

        
       <div className="h-5/6">
       
   
            <section id="confirmation" className="py-24 w-5/6 mx-auto justify-center">


        
                <Box className="flex justify-center" m="90px auto" width="80%" height="">
                    <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                    
                        <strong> You have successfully made a booking. A confirmation email has been sent — {" Thank you!"} </strong>
                    </Alert>
                </Box>

                <div>

                <motion.div 
                            initial="hidden"
                         // whileInView={{opacity: 1 }}  
                            whileHover={{ scale: 0.8 }}
                            whileInView={"visible"}
                            viewport={{ once: true, amount: 0.1 }}
                            transition={{duration: 0.5}}
                            variants={{
                            hidden: {opacity: 1, x: -50 },
                            visible : {opacity: 1, x:0 }
                    }}
                    >

            
                            {/* <a className="hover:text-mint" href="https://buy.stripe.com/5kAg0w8zE77O7Ru000" target="_blank">Tip</a> */}
                            <a className="flex justify-center" target="_blank" rel="noreferrer"  href="https://buy.stripe.com/5kAg0w8zE77O7Ru000">
                                    <img className ="h-24" src="../assets/tip-jar-grey.png"   alt="Tip" />
                                </a>

                    </motion.div>

                    {/* <a className="flex justify-center" target="_blank" rel="noreferrer"  href="https://buy.stripe.com/5kAg0w8zE77O7Ru000">
                                    <img className ="h-24" src="../assets/tip-jar-grey.png"   alt="Tip" />
                                </a> */}

                </div>

                {/* <div className="flex justify-center basis-3/5 z-10 font-semibold">

               
                    {serviceName}<br />
                 
                    {"Start:" + " " + startDate + " " + startTime} <br />
                    {"End:" + " " + endDate + " " + " " + endTime}
                   

                </div> */}

{/*                 
                <div className="flex justify-center basis-3/5 z-10">
              

                        <p className="mb-1 text-md text-center md:text-start text-black font-semibold">
                        <br />

                        Optional Gratuity - Also available after the service is completed </p>
                   
                    </div>

                    <div className="flex justify-center basis-3/5 z-10">

                    <a 
                            className="p-5 bg-mint font-semibold text-black mt-5 text-md hover:bg-salmon hover:text-black transition duration-500 rounded"
                            href="https://buy.stripe.com/5kAg0w8zE77O7Ru000" 
                            target="_blank">
                            
                        Tip
                        </a>


                        
                    </div> */}

            </section>

        </div>
            
    )
};


export default Confirmation;