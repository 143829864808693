import React, { useState } from 'react'; 

// import Amplify, { API } from 'aws-amplify';
import { get } from 'aws-amplify/api';
import { Amplify } from "aws-amplify";

import awsConfig from '../aws-exports';
import { fetchAuthSession } from 'aws-amplify/auth'
import { useEffect } from 'react';


// import amplifyconfig from '../amplifyconfiguration.json'

Amplify.configure(awsConfig);



const MyComponent = () => {

  const sendEmail = async () => {

    const eventPayload = {
      emailAddress: 'jonshawtech@gmail.com',

    };
    try {
      const response = await fetch('https://2u0un62n37.execute-api.us-east-1.amazonaws.com/staging/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventPayload),
      });

      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending email:', error.message);
    }
  };
  // const [lambdaResponse, setLambdaResponse] = useState('');


  // async function sendEmail() {
  //   try {
  //     // const requestIngo = {
  //     //   headers: {
  //     //     Authorization: authToken
  //     //   }
  //     // }
  //     const restOperation = get({ 
  //       apiName: 'fetchKeyApi',
  //       path: '/key' 

  //     });
  //     const response = await restOperation.response;
  //     console.log('GET call succeeded: ', response);
  //     setLambdaResponse(response);
  
 
  //   } catch (error) {
  //     console.log('GET call failed: ', error);
  //   }
  // }

  // useEffect(() => {
  //   // getTodo(); // Call your function here
  // }, []); 

  return (
    <div>
      <button onClick={sendEmail}>Call Lambda Function</button>
      {/* <div>{lambdaResponse}</div> */}
    </div>
  );
};

export default MyComponent;
