import Landing from "./Landing";
import Services from "./Services";
import About from "./About";
import Contact from "./Contact";
import Testimonials from "./Testimonials";
import Test from "./Test"


function Home() {
    return (

        <div>
            <Landing/>
            <About/>
            {/* <Test/> */}

            <Services/>
            {/* <Testimonials/>s */}
            <Contact/>

        </div>




    ) 


}

export default Home;

