import { motion } from "framer-motion";
import { useForm } from "react-hook-form";


const Contact = () => {

    const {
        register,
        trigger,
        formState: {errors}
        
    } = useForm();

    const onSubmit = async (e) => {

        const isValid = await trigger();
        if( !isValid) {
            e.preventDefault();
        }
    }

    return (

        <section id="contact" className="py-48 w-5/6 mx-auto">

        
            {/* HEADINGS */}

            <motion.div 
                className="flex justify-end w-full"                
                initial="hidden"
                whileInView={"visible"}
                viewport={{ once: true, amount: 0.5 }}
                transition={{duration: 0.5}}
                variants={{
                    hidden: {opacity: 0, x:-50 },
                    visible : {opacity: 1, x: 0  }}
                }
            >

                <div>
                
                    <p className="font-euphoria text-6xl mb-5 flex justify-center w-full">
                    <span className="text-salmon"> Contact me</span>
                       
                    
                    </p>

                    <div className="flex justify-center">
                        

                    </div>
                  

           
                         {/* FORM and IMAGE*/}

                         <div className ="md:flex md:justify-between gap-16 mt-5">
                            <motion.div
                                className="basis-1/2 flex justify-center"
                                initial="hidden"
                                whileInView={"visible"}
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{duration: 0.5}}
                                variants={{
                                    hidden: {opacity: 0, y: 50 },
                                    visible : {opacity: 1, y: 0  }}
                                }
                            >
                            <iframe src="https://calendar.google.com/calendar/embed?height=450&wkst=1&bgcolor=%23ffb7b7&ctz=America%2FNew_York&showPrint=0&showNav=1&showTabs=0&showCalendars=1&showDate=0&showTz=0&src=OTdlZDM4YzMzYTcwOTkyMDM1MjY5ZGU5OWFiZjYzZmE2MmFkNzhmZTViMDRkNDhjNDI1NjM2ZWNkNjE5OWRjNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23cc8181"
                              
                            style={{float : 'left',borderWidth:"0", paddingRight : '1px', height: '350px', width: '350px', frameborder:"0", scrolling:"no"}}></iframe>

                                {/* <img src="../assets/photo-dogs.png" alt="contact" /> */}
                     
                              
                                
                            </motion.div>


                                <motion.div 
                                    className="basis-1/2 mt-10 md:mt-0"
                                    initial="hidden"
                                    whileInView={"visible"}
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{duration: 0.5}}
                                    variants={{
                                        hidden: {opacity: 0, y: 50 },
                                        visible : {opacity: 1, y: 0  }}
                                    }
                                >

                                    <form
                                        
                                        target="_blank"
                                        onSubmit={onSubmit}
                                        action="https://formsubmit.co/39004f3535b8fa1732da57d7100b1484"
                                        method="POST"
                                    
                                    >
                                        <input  
                                            className="w-full bg-mint font-semibold placeholder-opaque-black p-3"
                                            type="text"
                                            placeholder="NAME"
                                            {...register("name", {
                                                required: true,
                                                maxLength: 100,
                                        })}
                                        
                                        />
                                        { errors.name && (

                                            <p className="text-red mt-1">
                                                {errors.name.type === 'required' && "This field is required" }
                                                {errors.name.type === 'maxLength' && "Max length is 100 char" }

                                            </p>
                                        )
                                        
                                        }
                                        <input
                                            className="w-full bg-mint font-semibold placeholder-opaque-black p-3 mt-5"
                                            type="text"
                                            placeholder="EMAIL"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            })}
                        
                                        />
                                        { errors.email && (

                                            <p className="text-red mt-1">
                                                {errors.email.type === 'required' && "This field is required" }
                                                {errors.email.type === 'pattern' && "Invalid email address" }

                                            </p>
                                        )

                                        }

                                        <textarea
                                            className="w-full bg-mint font-semibold placeholder-opaque-black p-3 mt-5"
                                            type="text"
                                            placeholder="MESSAGE"
                                            rows="4"
                                            cols="50"
                                            {...register("message", {
                                                required: true,
                                                maxLength: 2000,
                                            })}
                        
                                        />
                                        { errors.message && (

                                            <p className="text-red mt-1">
                                                {errors.message.type === 'required' && "This field is required" }
                                                {errors.message.type === 'maxLength' && "Max length is 2000 char." }

                                            </p>
                                        )}

                                        <button
                                            type="submit"
                                            className="p-5 bg-salmon font-semibold text-black mt-5 hover:bg-mint hover:text-black transition duration-500 rounded"
                                        >

                                            SEND MESSAGE
                                        </button>





                                    </form>



                                </motion.div>




                         </div>



                </div>




            </motion.div>



        </section>
    )
};



export default Contact;