

const Footer = () => {

    return (

   
        <footer className="h-64 pt-10 bg-salmon">
            <div className="w-5/6 mx-auto"> 
            
                <div className="md:flex justify-center md:justify-between text-center">
                <p className="font-playfair font-semibold text-xl text-white">
                        
                Dogs | Cats | Pet Sitting | Drop In Visits | Walking | Boarding
                
                </p>
                <p className="font-playfair font-semibold text-lg text-black">

                Covered through PetCare insurance
                </p>
                
                <a className="flex mt-5 justify-center md:justify-start" target="_blank" href="https://www.petcareins.com/pci-liability-insurance-simplified">

                    <img className ="h-16" src="../assets/pet-care-ins-logo.png"   alt="ins" /> </a>

                <p className="font-playfair font-semibold text-md text-black ">
                    ©2023 Steph's Pet Retreat
                </p>

                </div>



            </div>

        </footer>

   


    );

};

export default Footer;