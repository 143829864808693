import { Amplify } from "aws-amplify";

import awsConfig from '../aws-exports'
Amplify.configure(awsConfig);

const sendEmail = async (email,subject,serviceName,startDate,endDate,startTime,endTime, formData) => {

    const eventPayload = {
      emailAddress: email,
      emailSubject: subject,
      emailServiceName: serviceName,
      emailStartDate: startDate,
      emailEndDate: endDate,
      emailStartTime: startTime,
      emailEndTime: endTime,
      emailFormData: formData
   
 

    };
    try {
      const response = await fetch(process.env.REACT_APP_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventPayload),
      });

      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending email:', error.message);
    }
  };

  export default sendEmail