
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import handleCheckout from "../../api/checkout";
import { Navigate, useNavigate } from 'react-router-dom';




import { Formik, Form, ErrorMessage, Field, useFormik } from "formik";
import * as Yup from "yup";
import Summary from "../checkout/Summary"
import { useSelector } from "react-redux";
// import { sendToMyEmail, sendToCustomerEmail } from "../../api";

import { useDispatch } from "react-redux";
import { addToEmail,addToFormData, addToEmailSent } from "../../state";

import { useHistory } from 'react-router-dom';




const Checkoutform = () => {
    const navigate = useNavigate();
   

   
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0,0)
       
        
        
        

    }, [])

   

    let cart = useSelector(state=> state.cart.lineItems)
      
    // setServices(items)
    const {
        register,
        trigger,
       
        formState: {errors}
        
    } = useForm();

    const onSubmit = async (values, e) => {

        const isValid = await trigger();
    
      
        if( !isValid) {

            e.preventDefault();
        }

        if (isValid) {
           
            const email = values.email; 
            dispatch(addToEmail(email)) 
            dispatch(addToFormData(values))
            dispatch(addToEmailSent('false'))

            if ( serviceName !== "Meet and Greet") {
                handleCheckout(cart);

            }

            if(serviceName == "Meet and Greet")
                navigate("/confirmation");
            

               


        }
    }

    let numPets = useSelector(state=> state.cart.pets);
    const service = useSelector(state=> state.cart.cart)
    const serviceName = service[service.length-1].services.name;


    numPets = numPets[numPets.length-1].numPets;


    let initialValues = {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        name: "",
        breed: "",
        age: "",
        size: "",
        sex: "",
        notes: ""

    }

    if (numPets == 2) {

        initialValues = {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            name: "",
            breed: "",
            age: "",
            size: "",
            sex: "",
            notes: "",
            name2: "",
            breed2: "",
            age2: "",
            size2: "",
            sex2: "",
            notes2: ""
    
        }
    } 


    if (numPets == 3) {

      initialValues = {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            name: "",
            breed: "",
            age: "",
            size: "",
            sex: "",
            notes: "",
            name2: "",
            breed2: "",
            age2: "",
            size2: "",
            sex2: "",
            notes2: "",
            name3: "",
            breed3: "",
            age3: "",
            size3: "",
            sex3: "",
            notes3: ""
    
        }
    } 
    // const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    ;


    let validationSchema = Yup.object( {
        email: Yup.string().required("Email is required").email("Invalid email address"),
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        phoneNumber: Yup.string().required("Phone number is required").max(20, "Invalid phone number"),
        name: Yup.string().required("Pet's name is required"),
        breed: Yup.string().required("Breed is required"),
        age: Yup.number().required("Age is required").test( 'Is positive?', 'Age must be a positive number', (value) => value > 0),
        size: Yup.string().required("Size is required"),
        sex: Yup.string().required("This field is required"),
        notes: Yup.string().max(2000, "2000 char limit")

    })

    if (numPets == 2) {

        validationSchema = Yup.object( {
            email: Yup.string().required("Email is required").email("Invalid email address"),
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            phoneNumber: Yup.string().required("Phone number is required").max(20, "Invalid phone number"),
            name: Yup.string().required("Pet's name is required"),
            breed: Yup.string().required("Breed is required"),
            age: Yup.number().required("Age is required").test( 'Is positive?', 'Age must be a positive number', (value) => value > 0),
            size: Yup.string().required("Size is required"),
            sex: Yup.string().required("This field is required"),
            notes: Yup.string().max(2000, "2000 char limit"),
            name2: Yup.string().required("Pet's name is required"),
            breed2: Yup.string().required("Breed is required"),
            age2: Yup.number().required("Age is required").test( 'Is positive?', 'Age must be a positive number', (value) => value > 0),
            size2: Yup.string().required("Size is required"),
            sex2: Yup.string().required("This field is required"),
            notes2: Yup.string().max(2000, "2000 char limit")
    
        })
    }

    if (numPets == 3) {

        validationSchema = Yup.object( {
            email: Yup.string().required("Email is required").email("Invalid email address"),
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            phoneNumber: Yup.string().required("Phone number is required").max(20, "Invalid phone number"),
            name: Yup.string().required("Pet's name is required"),
            breed: Yup.string().required("Breed is required"),
            age: Yup.number().required("Age is required").test( 'Is positive?', 'Age must be a positive number', (value) => value > 0),
            size: Yup.string().required("Size is required"),
            sex: Yup.string().required("This field is required"),
            notes: Yup.string().max(2000, "2000 char limit"),
            name2: Yup.string().required("Pet's name is required"),
            breed2: Yup.string().required("Breed is required"),
            age2: Yup.number().required("Age is required").test( 'Is positive?', 'Age must be a positive number', (value) => value > 0),
            size2: Yup.string().required("Size is required"),
            sex2: Yup.string().required("This field is required"),
            notes2: Yup.string().max(2000, "2000 char limit"),
            name3: Yup.string().required("Pet's name is required"),
            breed3: Yup.string().required("Breed is required"),
            age3: Yup.number().required("Age is required").test( 'Is positive?', 'Age must be a positive number', (value) => value > 0),
            size3: Yup.string().required("Size is required"),
            sex3: Yup.string().required("This field is required"),
            notes3: Yup.string().max(2000, "2000 char limit"),
    
        })
    }
    // console.log(Formik.values)
    const formik = useFormik(initialValues);
  
    return (
        
 
       <section className=" md:flex md:justify-between py-32 md:w-1/2 mx-auto">
  

                <div className="md:order-2 flex justify-center basis-3/5 md:py-32 pb-2 h-1/2">
                    
                    <Summary/>
           
                </div>
       
        <div className="w-full"> 
        
             <p className="font-playfair font-bold text-xl flex justify-center w-full pt-8">

                        Contact
                </p>
          
            
                     {/* FORM */}
                
                     <div className ="py-8 w-5/6 mx-auto ">
                        

                     <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        >
                        {() => (
                            <Form>
       
                                <div className="field">
                                    <Field  
                                        className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" 
                                        name="email"                                                                             
                                        placeholder="Email"                                                                        
                                            />
                                    <div className="error text-red">
                                        <ErrorMessage name="email" component="span" />
                                    </div>
                                </div>
                                <div className="field">
                                    <Field 
                                        className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md " 
                                        name="firstName" 
                                        placeholder="First Name"
                                
                                        />
                                    <div className="error text-red">
                                        <ErrorMessage name="firstName" component="span" />
                                    </div>
                                </div>

                                <div className="field">
                                    <Field 
                                        className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" 
                                        name="lastName" 
                                        placeholder="Last Name"
                                       
                                        />
                                    <div className="error text-red">
                                        <ErrorMessage name="lastName" component="span" />
                                    </div>
                                </div>

                                <div className="field">
                                    <Field 
                                        className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" 
                                        name="phoneNumber" 
                                        placeholder="Phone Number"
                             
                                        />
                                    <div className="error text-red">
                                        <ErrorMessage name="phoneNumber" component="span" />
                                    </div>
                                </div>
                                
                                {/*PET FORM*/}

                                <p className="font-playfair font-bold text-xl flex justify-center w-full pt-5 pb-5 ">Pet</p>

                                <div className="field">
                                    <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="name" placeholder="Pet Name"/>
                                    <div className="error text-red">
                                        <ErrorMessage name="name" component="span" />
                                    </div>
                                </div>
                                <div className="field">
                                    <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md " name="breed" placeholder="Breed"/>
                                    <div className="error text-red">
                                        <ErrorMessage name="breed" component="span" />
                                    </div>
                                </div>

                                <div className="field">
                                    <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="age" placeholder="Age"/>
                                    <div className="error text-red">
                                        <ErrorMessage name="age" component="span" />
                                    </div>
                                </div>

                                <div className="field" >
                              
                                    <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="size">
                                        <option value="" label="Size">
                                            Size{""}
                                        </option>
                                        <option value="Small: (0-15) lbs">Small - (0-15) lbs</option>
                                        <option value="Medium: (16-40) lbs">Medium: (16-40) lbs</option>
                                        <option value="Large: (41-100) lbs">Large: (41-100) lbs</option>
                                        <option value="Extra Large: (101+) lbs">Extra Large: (101+) lbs</option>
                                    </Field>
                                    <div className="error text-red">
                                        <ErrorMessage name="size" component="span" />
                                    </div>
                                </div>
                                
                                <div className="field">
                                <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="sex">
                                        <option value="" label="Sex">
                                            Size{""}
                                        </option>
                                        <option value="M">M</option>
                                        <option value="F">F</option>
                                    
                                    </Field>
                                    <div className="error text-red">
                                        <ErrorMessage name="sex" component="span" />
                                    </div>
                                </div>

                                <div className="field">
                                    <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="notes" placeholder="Notes" component="textarea" rows="4"/>
                                    <div className="error text-red">
                                        <ErrorMessage name="notes" component="span" />
                                    </div>
                                </div>
                             

                                { numPets == 2 &&

                                <div>

                                    <p className="font-playfair font-bold text-xl flex justify-center w-full pt-5 pb-5 ">Pet 2</p>

                                    <div className="field">
                                        <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="name2" placeholder="Pet Name"/>
                                        <div className="error text-red">
                                            <ErrorMessage name="name2" component="span" />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md " name="breed2" placeholder="Breed"/>
                                        <div className="error text-red">
                                            <ErrorMessage name="breed2" component="span" />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="age2" placeholder="Age"/>
                                        <div className="error text-red">
                                            <ErrorMessage name="age2" component="span" />
                                        </div>
                                    </div>

                                    <div className="field" >

                                        <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="size2">
                                            <option value="" label="Size">
                                                Size{""}
                                            </option>
                                            <option value="Small: (0-15) lbs">Small - (0-15) lbs</option>
                                            <option value="Medium: (16-40) lbs">Medium: (16-40) lbs</option>
                                            <option value="Large: (41-100) lbs">Large: (41-100) lbs</option>
                                            <option value="Extra Large: (101+) lbs">Extra Large: (101+) lbs</option>
                                        </Field>
                                        <div className="error text-red">
                                            <ErrorMessage name="size2" component="span" />
                                        </div>
                                    </div>

                                    <div className="field">
                                    <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="sex2">
                                            <option value="" label="Sex">
                                                Size{""}
                                            </option>
                                            <option value="M">M</option>
                                            <option value="F">F</option>
                                        
                                        </Field>
                                        <div className="error text-red">
                                            <ErrorMessage name="sex2" component="span" />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="notes2" placeholder="Notes" component="textarea" rows="4"/>
                                        <div className="error text-red">
                                            <ErrorMessage name="notes2" component="span" />
                                        </div>
                                    </div>


                                </div>
                                
                                }
                   
                   { numPets == 3 &&

                        <div>
                            <p className="font-playfair font-bold text-xl flex justify-center w-full pt-5 pb-5 ">Pet 2</p>

                        <div className="field">
                            <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="name2" placeholder="Pet Name"/>
                            <div className="error text-red">
                                <ErrorMessage name="name2" component="span" />
                            </div>
                        </div>
                        <div className="field">
                            <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md " name="breed2" placeholder="Breed"/>
                            <div className="error text-red">
                                <ErrorMessage name="breed2" component="span" />
                            </div>
                        </div>

                        <div className="field">
                            <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="age2" placeholder="Age"/>
                            <div className="error text-red">
                                <ErrorMessage name="age2" component="span" />
                            </div>
                        </div>

                        <div className="field" >

                            <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="size2">
                                <option value="" label="Size">
                                    Size{""}
                                </option>
                                <option value="Small: (0-15) lbs">Small - (0-15) lbs</option>
                                <option value="Medium: (16-40) lbs">Medium: (16-40) lbs</option>
                                <option value="Large: (41-100) lbs">Large: (41-100) lbs</option>
                                <option value="Extra Large: (101+) lbs">Extra Large: (101+) lbs</option>
                            </Field>
                            <div className="error text-red">
                                <ErrorMessage name="size2" component="span" />
                            </div>
                        </div>

                        <div className="field">
                        <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="sex2">
                                <option value="" label="Sex">
                                    Size{""}
                                </option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                            
                            </Field>
                            <div className="error text-red">
                                <ErrorMessage name="sex2" component="span" />
                            </div>
                        </div>

                        <div className="field">
                            <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="notes2" placeholder="Notes" component="textarea" rows="4"/>
                            <div className="error text-red">
                                <ErrorMessage name="notes2" component="span" />
                            </div>
                        </div>



                            <p className="font-playfair font-bold text-xl flex justify-center w-full pt-5 pb-5 ">Pet 3</p>

                            <div className="field">
                                <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="name3" placeholder="Pet Name"/>
                                <div className="error text-red">
                                    <ErrorMessage name="name3" component="span" />
                                </div>
                            </div>
                            <div className="field">
                                <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md " name="breed3" placeholder="Breed"/>
                                <div className="error text-red">
                                    <ErrorMessage name="breed3" component="span" />
                                </div>
                            </div>

                            <div className="field">
                                <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="age3" placeholder="Age"/>
                                <div className="error text-red">
                                    <ErrorMessage name="age3" component="span" />
                                </div>
                            </div>

                            <div className="field" >

                                <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="size3">
                                    <option value="" label="Size">
                                        Size{""}
                                    </option>
                                    <option value="Small: (0-15) lbs">Small - (0-15) lbs</option>
                                    <option value="Medium: (16-40) lbs">Medium: (16-40) lbs</option>
                                    <option value="Large: (41-100) lbs">Large: (41-100) lbs</option>
                                    <option value="Extra Large: (101+) lbs">Extra Large: (101+) lbs</option>
                                </Field>
                                <div className="error text-red">
                                    <ErrorMessage name="size3" component="span" />
                                </div>
                            </div>

                            <div className="field">
                            <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" as="select" name="sex3">
                                    <option value="" label="Sex">
                                        Size{""}
                                    </option>
                                    <option value="M">M</option>
                                    <option value="F">F</option>
                                
                                </Field>
                                <div className="error text-red">
                                    <ErrorMessage name="sex3" component="span" />
                                </div>
                            </div>

                            <div className="field">
                                <Field className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-lg shadow-md" name="notes3" placeholder="Notes" component="textarea" rows="4"/>
                                <div className="error text-red">
                                    <ErrorMessage name="notes3" component="span" />
                                </div>
                            </div>


                        </div>

                        }

                        <div className="flex space-x-2">

                            <a 
                                className="p-5 bg-salmon font-semibold text-black mt-5 hover:bg-salmon hover:text-black transition duration-500 rounded"
                                href="/" >                            
                                 Cancel
                            </a>
                    

                                <button 
                                    className="p-5 bg-mint font-semibold text-black mt-5 hover:bg-salmon hover:text-black transition duration-500 rounded"
                                    type="submit"
                                    >   

                                    Book
                                </button>
                              
                        </div>
                              
                            </Form>
                            )}
                        </Formik>
        
   
                       
                     </div>
                    </div>
            </section>

    );
};

export default Checkoutform;



