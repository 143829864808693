import { configureStore } from "@reduxjs/toolkit";

import state from ".";

const store = configureStore({

    reducer: {
        cart: state
    }
})


export default store;