
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";



const About = () => {
    const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
 




    return(
        <section id="about" className="md:flex md:justify-between md:items-center md:h-full gap-16 py-12 w-5/6 mx-auto">

            {/* IMAGE */}

              <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16">

                { isAboveMediumScreens ? (

                    <div>

                
                        <img 
                            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-t-[400px]"
                            src="../assets/steph-profile-img.png" 
                            alt="profile-img"
                         />


                    </div>

                ) : (
                    <img 
                        className="z-10 max-w-[350px] md:max-w-[600px] rounded-t-[400px]"
                        src="../assets/steph-profile-img.png" 
                        alt="profile-img"
                    />
                
                )}                   

                
            </div>

            { /* MAIN TEXT */}

                <div className="z-10 basis-2/5 mt-2 md:mt-32">
                { /* HEADINGS framer motion */}
                <motion.div 
                    initial="hidden"
                    // whileInView={{opacity: 1 }}
                    whileInView={"visible"}
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{duration: 0.5}}
                    variants={{
                    hidden: {opacity: 1, x: -50 },
                    visible : {opacity: 1, x:0 }
                }}
                    >

            <p className="text-6xl font-playfair z-10 text-center md:text-start text-salmon">
                <span className="font-euphoria">About {""} </span>         

       
                 {/* CALL TO ACTIONS */}
        
                        </p>
       

                        <p className="mt-10 mb-7 text-lg text-center md:text-start text-black font-semibold">
                        Hi, I'm Stephanie and I absolutely adore all dogs! I have many years of professional experience walking dogs, as well as sitting,
                         and boarding pets in my home. I cater to different dogs of all breeds and sizes as well as cats, senior dogs and special needs pets.
                          I currently do not have any pets of my own so I provide personalized care for each client and typically serve one family at 
                          a time unless discussed and approved otherwise. I am more than happy to accommodate all of your pets individual needs and just
                           give them a wonderful retreat while you’re away!
                          
                        </p>

                        <div className="bg-mint relative max-w-[400px] h-[350] flex flex-col text-start md:text-start
                            justify-start p-12 rounded">                 
                            
                                
                        <ul className="list-disc font-semibold text-lg ">                  
                            <li> Free meet-and-greets before all bookings to meet you and your pet </li>
                            <br />
                            <li>  Photo and video updates are sent throughout all boardings, walks and visits</li>
                            <br />
                            <li>  Single family home with spacious fenced in backyard to ensure plenty of exercise and accessible potty breaks  </li>
                    
                    
                        </ul>   
                        
                                      

                                            
            </div> 
            
                  




   
                </motion.div> 

                {/* Offers */}

                
                </div>     

                <div className="md:flex md:justify-betIen md:gap-16 mt:32">
 
           


      </div>
      

                
        </section>
    )


}

export default About;