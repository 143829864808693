import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";


const Navbar = () => {

   
    const navbarBackground = "bg-salmon";


      
    const [isMenuToggled, setIsMenuToggled] = useState(false);
    const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");



    return (
       

        <nav className={`${navbarBackground} z-40 w-full fixed top-0 py-1`}>
            

            <div className="flex items-center justify-between mx-auto w-5/6">
      
                
            <img className="self-center" src="../assets/logo_trans_bg.png" alt="logo" ></img>
                             


                { /* Desktop MENU View */}

        
                {isAboveSmallScreens ? (
                    <div className="flex justify-between gap-10 font-opensans text-lg font-bold">

                            <a className="hover:text-mint pt-8" href="/">Home</a>
                            <a className="hover:text-mint pt-8" href="/about">About</a>
                            <a className="hover:text-mint pt-8" href="/services">Services</a>
                            <a className="hover:text-mint pt-8" href="/testimonials">Testimonials</a>
                            <a className="hover:text-mint pt-8" href="/contact">Contact</a>
                            <a className="hover:text-mint pt-8" href="/book">Book</a>

                            <motion.div 
                            initial="hidden"
                         // whileInView={{opacity: 1 }}  
                            whileHover={{ scale: 0.8 }}
                            whileInView={"visible"}
                            viewport={{ once: true, amount: 0.1 }}
                            transition={{duration: 0.5}}
                            variants={{
                            hidden: {opacity: 1, x: -50 },
                            visible : {opacity: 1, x:0 }
                    }}
                    >

                        <a className="" target="_blank" rel="noreferrer"  href="https://buy.stripe.com/5kAg0w8zE77O7Ru000">
                                <img className ="h-24" src="../assets/tip-jar-pink.png"   alt="Tip" />
                            </a>
                            {/* <a className="hover:text-mint" href="https://buy.stripe.com/5kAg0w8zE77O7Ru000" target="_blank">Tip</a> */}

                    </motion.div> 
                       


                      
                    </div>

                ) : (
                
                
                <button
                    className="rounded-full bg-mint p-2"
                    onClick={()=> setIsMenuToggled(!isMenuToggled)}
                >
                        <img alt="menu-icon" src="../assets/menu-icon.png"></img>


                </button>
                
                
                )}

                 {/* MOBILE VIEW MENU*/}

                 {!isAboveSmallScreens && isMenuToggled && (
                        <div className="fixed right-0 bottom-0 h-full bg-background w-[300px]">
                            {/* CLOSE ICON*/}

                            <div className="flex justify-end p-12">
                                <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                                    <img alt="close-icon" src="../assets/arrow.png"></img>


                                </button>


                            </div>

                            {/* MENU ITEMS */}

                            <div className="flex flex-col gap-10 ml-[33%] text-lg font-bold">

                            <a className="hover:text-mint" href="/">Home</a>
                            <a className="hover:text-mint" href="/about">About</a>
                            <a className="hover:text-mint" href="/services">Services</a>
                            <a className="hover:text-mint" href="/testimonials">Testimonials</a>
                            <a className="hover:text-mint" href="/contact">Contact</a>
                            <a className="hover:text-mint" href="/book">Book</a>
                            <motion.div 
                            initial="hidden"
                         // whileInView={{opacity: 1 }}  
                            whileHover={{ scale: 0.8 }}
                            whileInView={"visible"}
                            viewport={{ once: true, amount: 0.1 }}
                            transition={{duration: 0.5}}
                            variants={{
                            hidden: {opacity: 1, x: -50 },
                            visible : {opacity: 1, x:0 }
                    }}
                    >

                        <a className="" target="_blank" rel="noreferrer"  href="https://buy.stripe.com/5kAg0w8zE77O7Ru000">
                                <img className ="h-24" src="../assets/tip-jar-grey.png"   alt="Tip" />
                            </a>
                            {/* <a className="hover:text-mint" href="https://buy.stripe.com/5kAg0w8zE77O7Ru000" target="_blank">Tip</a> */}

                    </motion.div> 
                            {/* <a className="hover:text-mint" href="https://buy.stripe.com/5kAg0w8zE77O7Ru000" target="_blank">Tip</a> */}

                            {/* <NavLink
                                                         
                                    page="Home"
                                    selectedPage={selectedPage}
                                    setSelectedPage={setSelectedPage}
                                 
                            
                            /> */}


                            </div>

                            
                

                        </div>



                 )}

            </div>



        </nav>
    );

};

export default Navbar