import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  
  cart: [],
  pets: [],
  dates: [],
  days: [],
  times: [],
  lineItems: [],
  pickUp: [],
  dropOff: [],
  email: [],
  formData: [],
  emailSent: [],
  walkDuration: []
  
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {

    addToCart: (state, action) => {
     
        state.cart.push(action.payload);
        localStorage.setItem("cart", JSON.stringify(state.cart.map
          (item=>item)))
     
    },

    addToPets: (state, action) => {
     
      state.pets.push(action.payload);
      localStorage.setItem('pets', JSON.stringify(state.pets.map
        (item=>item)))
      
    
    },

    addToPickUp: (state, action) => {
     
      state.pickUp.push(action.payload);
      localStorage.setItem('pickUp', JSON.stringify(state.pickUp.map
        (item=>item)))
      
    
    },

    addToDropOff: (state, action) => {
     
      state.dropOff.push(action.payload);
      localStorage.setItem('dropOff', JSON.stringify(state.dropOff.map
        (item=>item)))
      
    
    },



    addToDays: (state, action) => {
      
      state.days.push(action.payload);
      localStorage.setItem('days', JSON.stringify(state.days.map
        (item=>item)))
    
  
  },

    addToTimes: (state, action) => {
        
      state.times.push(action.payload);
      localStorage.setItem('times', JSON.stringify(state.times.map
        (item=>item)))
    

  },

  addToDates: (state, action) => {
        
    state.dates.push(action.payload);
    localStorage.setItem('dates', JSON.stringify(state.dates.map
      (item=>item)))
 

},


addToEmail: (state, action) => {
      
  state.email.push(action.payload);
  localStorage.setItem('email', JSON.stringify(state.email.map
    (item=>item)))
},


addToLineItems: (state, action) => {
        
  state.lineItems.push(action.payload);
  localStorage.setItem('lineItems', JSON.stringify(state.lineItems.map
    (item=>item)))


},

addToFormData: (state, action) => {
        
  state.formData.push(action.payload);
  localStorage.setItem('formData', JSON.stringify(state.formData.map
    (item=>item)))


},

addToEmailSent: (state, action) => {
        
  state.emailSent.push(action.payload);
  localStorage.setItem('emailSent', JSON.stringify(state.emailSent.map
    (item=>item)))


},

addToWalkDuration: (state, action) => {
        
  state.walkDuration.push(action.payload);
  localStorage.setItem('walkDuration', JSON.stringify(state.walkDuration.map
    (item=>item)))


}

    // removeFromCart: (state, action) => {
    //   state.cart = state.cart.filter((item) => item.id !== action.payload.id);
    // },

  },


});



export const {
  addToCart,
  removeFromCart,
  addToDays,
  addToPets,
  addToTimes,
  addToDates,
  addToLineItems,
  addToEmail,
  addToFormData,
  addToDropOff,
  addToPickUp,
  addToEmailSent,
  addToWalkDuration
} = cartSlice.actions;

export default cartSlice.reducer;